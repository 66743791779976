<template>
  <div class="auth_wrap">
    <!-- 未提交认证 -->

    <template v-if="!authing">
      <div class="NoAuth">
        <span class="hint">温馨提示：当前账户尚未完成企业认证</span>
        <span class="immediateAuth" @click="authing = true">立即认证</span>
      </div>
    </template>
    <template v-else>
      <div class="auth_info">
        <el-form ref="companyForm" label-position="right" :rules='rules' :model="form" label-width="140px">
          <div class='group_title'>企业信息</div>
          <el-row>
            <el-col :span='span'>
              <el-form-item label="企业名称：" prop="companyName">
                <el-input v-model='form.companyName' placeholder='请输入企业营业执照上的名称，务必保持一致'></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span='span'>
              <el-form-item label="营业执照扫描件：" prop="imageUrl">
                <el-upload class="avatar-uploader" action="" :show-file-list="false" :http-request='uploadFile'
                  :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" accept=".jpg,.png,.jpeg">
                  <img v-if="form.imageUrl" :src="form.imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <div class="el-upload__tip" slot="tip">请提供证件的原件照片或彩色扫描件（正副本均可），文字/盖章清晰可辨认，格式要求jpg、jpeg、png，不超过4MB
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span='span'>
              <el-form-item label="所属行业：" prop="industry">
                <el-select v-model="form.industry" placeholder="请选择所属行业">
                  <el-option v-for="(item,index) in industryList" :key="index" :label="item.name"
                    :value="item.name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span='span'>
              <el-form-item label="官网地址：" prop="companyUrl">
                <el-input v-model='form.companyUrl' placeholder='请输入您公司的官网地址'></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span='span'>
              <el-form-item label="公司简介：" prop="companyDetail">
                <el-input type="textarea" v-model='form.companyDetail' maxlength="500" show-word-limit
                  :autosize="{ minRows: 3, maxRows: 8}" placeholder='请填写您公司的简介及业务描述，限制500字'></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span='span'>
              <el-form-item label="公司地址：" prop="address">
                <el-input v-model='form.address' placeholder='请输入公司地址'></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span='span'>
              <el-form-item label="资质荣誉：">
                <el-upload action="" class="upload-demo" drag :on-change='changeFileList' :file-list='fileList'
                  :http-request='uploadFiles' multiple accept=".jpg,.png,.jpeg">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传.jpg .png .jpeg文件</div>
                </el-upload>

              </el-form-item>
            </el-col>
          </el-row>

          <div class='group_title'>联系人信息</div>
          <el-row>
            <el-col :span='span'>
              <el-form-item label="联系人姓名：" prop="contactName">
                <el-input v-model='form.contactName' placeholder='请输入姓名'></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span='span'>
              <el-form-item label="联系人职务：" prop="contactPosition">
                <el-input v-model='form.contactPosition' placeholder='请输入联系人职务'></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span='span'>
              <el-form-item label="联系人电话：" prop="contactPhone">
                <el-input v-model='form.contactPhone' placeholder='输入的手机号码务必于当前注册的手机号保持一致'></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span='span'>
              <el-form-item label="联系人邮箱：" prop="email">
                <el-input v-model='form.email' placeholder='请输入联系人邮箱'></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="opera_btn">
          <el-button @click='cancel' type="primary" class="common-btn"> 取 消 </el-button>
          <el-button @click='submitForm' type='primary' class="common-btn"> 提 交
          </el-button>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import api from '@/api/api'
export default {
  data () {
    return {
      authing: false,
      form: {
        imageUrl: ''
      },
      span: 22,
      file: '',
      fileList: [],

      rules: {
        companyName: [
          { required: true, message: '请填写必填项', trigger: 'blur' },
        ],
        companyDetail: [
          { required: true, message: '请填写必填项', trigger: 'blur' },
        ],
        address: [
          { required: true, message: '请填写必填项', trigger: 'blur' },
        ],
        contactName: [
          { required: true, message: '请填写必填项', trigger: 'blur' },
        ],
        contactPosition: [
          { required: true, message: '请填写必填项', trigger: 'blur' },
        ],
        contactPhone: [
          { required: true, message: '请填写必填项', trigger: 'blur' },
        ],
        email: [
          { required: true, message: '请填写必填项', trigger: 'blur' },
        ],
        industry: [
          { required: true, message: '请填写必填项' },
        ],
        imageUrl: [
          { required: true, message: '请填写必填项', trigger: 'change' },
        ],
      },
      industryList: []

    }
  },

  created () {
    this.getIndustryList();
  },
  methods: {
    getIndustryList () {
      api.queryDictDetail('industry_type').then(res => {
        if (res.code == 200) {
          this.industryList = res.data;
        }
      })
    },
    uploadFile (uploadData) {
      uploadData.onSuccess(uploadData.file);
    },
    handleAvatarSuccess (res, file) {
      this.file = file;
      this.form.imageUrl = URL.createObjectURL(file.raw);
    },
    uploadFiles () {
    },
    beforeAvatarUpload (file) {
      const allowedTypes = ['image/jpeg', 'image/png'];
      const isAllowedType = allowedTypes.includes(file.type);
      const isLt4M = file.size / 1024 / 1024 < 4;
      if (!isAllowedType) {
        this.$message.error('上传头像图片只能是 JPG、JPEG 或 PNG 格式!');
        return false;
      }
      if (!isLt4M) {
        this.$message.error('上传头像图片大小不能超过 4MB!');
        return false;
      }
      return true;
    },
    changeFileList (file, fileList) {
      this.fileList = fileList;

    },
    reset () {
      this.file = ''
      this.fileList = []
      this.form = {}
      this.companyVisible = false
      this.$refs.companyForm.resetFields();
    },
    cancel () {
      this.reset()
      this.authing = false;
    },
    submitForm () {
      this.$refs.companyForm.validate((valid) => {
        if (valid) {
          this.loading = true
          let formData = new FormData()
          for (var key in this.form) {
            if (Object.prototype.hasOwnProperty.call(this.form, key)) {
              if (key !== 'imageUrl') {
                formData.append(key, this.form[key]);
              }
            }
          }
          if (this.file) formData.append('file', this.file.raw);
          if (this.fileList && this.fileList.length > 0) {
            this.fileList.forEach((item) => {
              formData.append(`files`, item.raw);
            });
          }
          // if(this.fileList && this.fileList.length > 0){
          //     let files = this.fileList.map((item,index) => {
          //         return item.raw
          //     })
          //     formData.append(`files`, files)
          // }
          api.createCompany(formData).then(res => {
            this.loading = false
            if (res.code == 200) {
              window.localStorage.setItem('company', JSON.stringify(res.data))
              window.location.reload();
            }
          })
            .catch(err => {
              this.loading = false
            })
        } else {
          return false;
        }
      });
    },
  },
}
</script>

<style lang="scss">
.auth_wrap {
  display: flex;
  font-size: 20px;
  background: #fff;
  .NoAuth {
    height: 100%;
    width: 100%;
    background: #f5f5f5;
    .immediateAuth {
      color: #027db4;
      margin-left: 30px;
      cursor: pointer;
    }
  }

  .auth_info {
    margin-left: 100px;
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #4073ff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
    .group_title {
      font-size: 18px !important;
      margin: 18px 0;
    }
  }
  .opera_btn {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}
</style>